import React, {SyntheticEvent, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {loadVideoSelectionData} from '../../../../common/redux/videoSelectionData';
import {useMount} from '../../../../hooks/useMount';
import {handleAxiosError} from '../../../../common/util/http';
import {CenteredSpinner} from '../../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {Col} from 'react-bootstrap';
import {WebState} from '../../../../redux/types/WebState';
import {RouteComponentProps} from 'react-router';
import {Label, labelStore} from '../../../../common/redux/entities/label';
import {getLogoLink} from '../../../../appTheme';
import {videoStore} from '../../../../common/redux/entities/video';
import ReactPlayer from 'react-player';
import styles from '../../PublicRouter.module.scss';
import {Button} from 'react-bootstrap/es';
import IconButton from '../../../../components/util/widgets/IconButton/IconButton';
import {machineStore} from '../../../../common/redux/entities/machine';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ConfirmationDialog} from '../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {userStore} from '../../../../common/redux/entities/user';

type Props = RouteComponentProps<{id: string}> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function VideoSelection(props: Props) {
  const {actions: {loadVideoData, acceptDisclaimer},
    labels, getRootLabels, getChildLabels, getVideoById, getLabelById, getMachine, hasAcceptedDisclaimer} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const params = new URLSearchParams(props.location.search);
  const [labelId, setLabelId] = useState('');
  const machineCategoryId = labels[0] ? labels[0].machineCategoryId : undefined;
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useMount(async () => {
    try {
      await loadVideoData(params.get('id')!);
    } catch (e) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load videos'}));
    }
    setLoading(false);
  });

  const getChildLabelSelections = () => getChildLabels(Number(labelId), Number(machineCategoryId)).map((label) => (
    <Col key={label.id} className={styles['selection-component']}>
      <Button
        key={label.id}
        style={{border: '1px', backgroundColor: '#005A9C', width: '100%', minHeight: '4rem'}}
        onClick={() => setLabelId(label.id.toString())}
      >
        {label.name}
      </Button>
    </Col>
  ));

  const getRootLabelSelections = () => getRootLabels(Number(machineCategoryId)).map((label) => (
    <Col key={label.id} className={styles['selection-component']}>
      <Button
        key={label.id}
        style={{border: '1px', backgroundColor: '#005A9C', width: '100%', minHeight: '4rem'}}
        onClick={async () => {
          if (!hasAcceptedDisclaimer) {
            setShowDisclaimer(true);
          }
          setLabelId(label.id.toString());
        }}
      >
        {label.name}
      </Button>
    </Col>
  ));

  const getVideoPlayer = (label: Label) => (
      <ReactPlayer
        url={getVideoById(label.videoId!).url.directUrlOriginal}
        playing={false}
        controls={true}
        onContextMenu={(e: SyntheticEvent) => e.preventDefault()}
        pip={false}
        width='100%'
        height='100%'
        style={{paddingTop: '1rem'}}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              disablePictureInPicture: true
            }
          }
        }}
      />
  );

  const getSubLabelContent = () => {
    const label = getLabelById(Number(labelId));
    if (label.videoId !== null && label.videoId !== '') {

      window.gtag('event', 'video_viewed', {
        serial_number: getMachine[0].serialNumber,
        video_name: getVideoById(label.videoId).name,
        rental_id: getMachine[0].rentalId
      });
      console.log("Test Local");
      return (
        <>
          {getVideoPlayer(label)}
          <hr/>
          {getChildLabelSelections()}
        </>
      );
    }    
    else {
      return getChildLabelSelections();
    }
  };

  const getLabelSelections = (): any => {
    if (!machineCategoryId) {
      return null;
    }
    if (labelId === '') {
      return getRootLabelSelections();
    } else {
      const label = getLabelById(Number(labelId));
      return (
        <>
          <div style={{display: 'flex'}}>
            <IconButton
              icon={'angle-left'}
              size={'2x'}
              color={'#005A9C'}
              onClick={() => setLabelId(label.parentId ? label.parentId.toString() : '')}
              styles={{paddingLeft: '1rem', paddingTop: '1rem'}}
            />
            <h4 style={{margin: '1rem auto auto 1rem'}}>{label.name}</h4>
          </div>
          {getSubLabelContent()}
        </>
      );
    }
  };

  const renderContent = () => {
    return (
      <div>
        <div style={{justifyContent: 'center', display: 'flex', paddingBottom: '1rem'}}>
          <img src={getLogoLink()} alt='ISCO Garage' style={{height: '3rem' }}/>
        </div>
        {getLabelSelections()}
        <Col
          className={styles['selection-component']}
          style={{paddingLeft: '2em', paddingRight: '2rem', paddingBottom: '1rem'}}
        >
          <Button
            style={{border: '1px', backgroundColor: '#ea0505', width: '100%', minHeight: '3rem', paddingTop: '.75rem'}}
            href={'tel:5023186612'}
          >
            <FontAwesomeIcon
              icon={'phone-alt'}
              size={'1x'}
              className={styles['icon']}
              color={'white'}
              style={{paddingRight: '2px'}}
            />
            Call the Fusion Hotline
          </Button>
        </Col>
        {showDisclaimer ?
          <ConfirmationDialog
            onAccept={async () => {
              await acceptDisclaimer();
              setShowDisclaimer(false);
            }}
            onDecline={async () => {
              window.location.href = 'https://isco-pipe.com';
            }}
            open={showDisclaimer}
            prompt={
              <>
                <h3>ISCO Garage Disclaimer</h3>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  ISCO Garage and the information, material, and content provided at ISCO Garage (collectively, the
                  “ISCO Garage Content”) and any intellectual property rights associated with the ISCO Garage Content
                  are owned by ISCO Industries, Inc. or its licensors (“ISCO”). ISCO grants no ownership interest in any
                  ISCO Garage Content or any such intellectual property rights from access to the ISCO Garage Content or otherwise.
                  ISCO grants a limited, non-exclusive, non-sublicensable, non-transferable, and revocable license to
                  access and use the ISCO Garage Content for your own personal informational and educational purposes only.
                  The ISCO Garage Content may not be reproduced, distributed, publicly performed, publicly displayed, shared,
                  or be used to create derivative works without first obtaining the express prior written permission to do so from ISCO.
                  <br/>
                  <br/>
                  The ISCO Garage Content is provided AS-IS without representations or warranties of any kind.
                  ISCO disclaims all representations and warranties related to the ISCO Garage Content, including
                  that the ISCO Garage Content is and will be complete, accurate, error-free, current, fit for a particular purpose,
                  non-infringing, and uninterrupted.  The ISCO Garage Content, and specifications and guidance for
                  products and processes featured in the ISCO Garage Content, are subject to change without notice, and your
                  access to and use of the ISCO Garage content is at your sole risk.  ISCO Industries, Inc. assumes no
                  responsibility or liability for your access to or use of, or reliance upon, the ISCO Garage Content.
                </div>
              </>
            }
            positiveText='Accept'
            negativeText='Decline'
            negativeVariant='danger'
          />
          : null}
      </div>
    );
  };

  return (
    <>
      {loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          renderContent()
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  ({actions: bindActionCreators({
      loadVideoData: loadVideoSelectionData,
      acceptDisclaimer: userStore.actions.acceptDisclaimer
    }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  labels: labelStore.selectors.getAsArray(state),
  getLabelById: labelStore.selectors.getById(state),
  getRootLabels: labelStore.selectors.getRootLabels(state),
  getChildLabels: labelStore.selectors.getChildLabels(state),
  getVideoById: videoStore.selectors.getVideoById(state),
  getMachine: machineStore.selectors.getAsArray(state),
  hasAcceptedDisclaimer: userStore.selectors.hasAcceptedDisclaimer(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(VideoSelection);
