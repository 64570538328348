import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {CommonDispatch} from '../index';
import {deleteMachineCategory, upsertMachineCategory} from '../../../api/machineConfigurationApi';
import {createAction} from 'typesafe-actions';

export interface MachineCategory {
  id: number;
  name: string;
}

const actions = createStandardActions(placeholder<MachineCategory>(), 'MACHINE_CATEGORY/SET', 'MACHINE_CATEGORY/SAVE');
export const deleteMachineCategoryAction = createAction('MACHINE_CATEGORY/DELETE')<number>();
export type MachineCategoryActions = GetActions<typeof actions> | ReturnType<typeof deleteMachineCategoryAction>;

export const machineCategories = combineReducers({
  items: standardItemsReducer<MachineCategory, MachineCategoryActions>(actions)
    .handleAction(deleteMachineCategoryAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload];
      return newState;
    })
});

export const machineCategoryStore = readonly({
  selectors: createStandardSelectors(placeholder<MachineCategory>(), s => getEntities(s).machineCategories),
  actions: {
    ...actions,
    upsert: (form: MachineCategory) => async (dispatch: CommonDispatch) => {
      const response = await upsertMachineCategory(form);
      dispatch(machineCategoryStore.actions.save(response));
      return response;
    },
    delete: (id: number) => async (dispatch: CommonDispatch) => {
      const response = await deleteMachineCategory(id);
      dispatch(deleteMachineCategoryAction(response.id));
      return response;
    }
  }
});
