import React, {useState} from 'react';
import {WebState} from '../../../../../redux/types/WebState';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {RoutePaths} from '../../../../../router/RoutePaths';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next';
import {Machine, machineStore} from '../../../../../common/redux/entities/machine';
import {machineCategoryStore} from '../../../../../common/redux/entities/machineCategory';
import {machineTypeStore} from '../../../../../common/redux/entities/machineType';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {bindActionCreators, Dispatch} from 'redux';
// import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

type Props = RouteComponentProps<{machineCategoryId: string; machineTypeId: string}>
  & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function Machines(props: Props) {
  const {match: {params: {machineCategoryId, machineTypeId}},
    machines, getMachineCategoryById, getMachineTypeById, actions: {deleteMachine}} = props;
  const [isDeleting, setIsDeletingMachine] = useState(0);

  const getPageHeaderArea = () => {
    const machineCategory = getMachineCategoryById(Number(machineCategoryId));
    const machineType = getMachineTypeById(Number(machineTypeId));
    return (
      <>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Link
            to={RoutePaths.machineTypeListPath.replace(':machineCategoryId', machineCategoryId)}
          >
            <IconButton
              icon={'angle-left'}
              size={'2x'}
              iconToolTipText={'Back'}
              color={'#005A9C'}
            />
          </Link>
          <div>
            <Link to={RoutePaths.machineCategoryListPath.replace(':machineCategoryId', String(machineCategory.id))}>
              Machine Categories
            </Link>
            <label> {' > '} </label>
            {machineCategory.name}
            <label> {' > '} </label>
            <Link to={RoutePaths.machineTypeListPath.replace(':machineCategoryId', machineCategoryId)}>
              Machine Types
            </Link>
            <label> {' > '} </label>
            {machineType.name}
          </div>
          <h4>{machineType.name}</h4>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', flexGrow: 1}}>
          <Link
            to={RoutePaths.machinePaths.New.replace(':machineCategoryId', machineCategoryId)
              .replace(':machineTypeId', machineTypeId)}
          >
            <IconButton
              icon={'plus-circle'}
              size={'2x'}
              iconToolTipText={'Add Machine'}
              color={'#005A9C'}
            />
          </Link>
        </div>
      </>
    );
  };

  const editMachineFormatter = (cell: any, machine: Machine) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <Link
            to={RoutePaths.machinePaths.Edit.replace(':machineCategoryId', machineCategoryId)
            .replace(':machineTypeId', machineTypeId)
            .replace(':machineId', machine.id.toString())}
          >
            <IconButton
              icon='pen'
              size={'1x'}
              color={'#005A9C'}
            />
          </Link>
        </div>
      </>
    );
  };

  const deleteMachineFormatter = (cell: any, machine: Machine) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <IconButton
            icon='minus'
            size={'1x'}
            color={'#005A9C'}
            onClick={() => setIsDeletingMachine(machine.id)}
          />
        </div>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'serialNumber',
      text: 'Serial Number'
    },
    {
      dataField: 'rentalId',
      text: 'Rental Id'
    },
    {
      dataField: 'description',
      text: 'Description'
    },
    {
      dataField: 'userActionsColumn',
      text: 'Edit Machine',
      isDummyField: true,
      formatter: editMachineFormatter,
      headerStyle: () => {
        return { width: '10rem'};
      },
      style: () => {
        return { width: '10rem'};
      }
    },
    {
      dataField: 'userActionsColumnTwo',
      text: 'Delete Machine',
      isDummyField: true,
      formatter: deleteMachineFormatter,
      headerStyle: () => {
        return { width: '10rem'};
      },
      style: () => {
        return { width: '10rem'};
      }
    }
  ];

  // const customTotal = (from: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined, to: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined, size: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined) => (
  //   <span className="react-bootstrap-table-pagination-total">
  //        &nbsp;&nbsp; Showing { from } to { to } of { size } Results
  //   </span>
  // );

  // const paginationOption = {
  //   paginationSize: 4,
  //   pageStartIndex: 1,
  //   //x alwaysShowAllBtns: true, // Always show next and previous button
  //   //x withFirstAndLast: false, // Hide the going to First and Last page button
  //   //x hideSizePerPage: true, // Hide the sizePerPage dropdown always
  //   //x hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
  //   firstPageText: 'First',
  //   prePageText: 'Back',
  //   nextPageText: 'Next',
  //   lastPageText: 'Last',
  //   nextPageTitle: 'First page',
  //   prePageTitle: 'Pre page',
  //   firstPageTitle: 'Next page',
  //   lastPageTitle: 'Last page',
  //   showTotal: true,
  //   hidePageListOnlyOnePage: true,
  //   paginationTotalRenderer: customTotal,
  //   disablePageTitle: true,
  //   sizePerPageList: [{
  //     text: '10', value: 10
  //   },
  //   {
  //     text: '50', value: 50
  //   },{
  //     text: '100', value: 100
  //   }] // A numeric array is also available. the purpose of above example is custom the text

  // };



  return (
    <>
      <div style={{marginBottom: '10px', display: 'flex', alignItems: 'flex-end'}}>
        {getPageHeaderArea()}
      </div>
      {/* <PaginationProvider
              pagination={ paginationFactory(paginationOption) }
            >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <div>
                    <BootstrapTable
                      keyField='id'
                      data={machines(Number(machineTypeId))}
                      columns={columns}
                      pagination={paginationFactory(paginationOption)}
                    />
                  </div>
                )
              }
            </PaginationProvider> */}
      <BootstrapTable
        keyField='id'
        data={machines(Number(machineTypeId))}
        columns={columns}
        // pagination={paginationFactory(paginationOption)}
      />

      {isDeleting !== 0 && (
        <ConfirmationDialog
          onAccept={async () => {
            await deleteMachine(isDeleting);
            setIsDeletingMachine(0);
            // alert(isDeleting);
          }}
          onDecline={async () => { setIsDeletingMachine(0); }}
          open={isDeleting !== 0}
          prompt='Are you sure you want to delete this machine?'
          positiveText='Yes'
          negativeText='No'
          positiveVariant='danger'
        />
      )}
    </>
  );
}

const mapStateToProps = (state: WebState) => ({
  machines: machineStore.selectors.getMachineByTypeId(state),
  getMachineCategoryById: machineCategoryStore.selectors.getById(state),
  getMachineTypeById: machineTypeStore.selectors.getById(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    deleteMachine: machineStore.actions.delete
  }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Machines);
