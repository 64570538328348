import {Dispatch} from 'redux';
import {Dictionary} from '../util';
import {batchActions} from 'redux-batched-actions';
import {getQrCodeManagementState} from '../../api/qrCodeManagementApi';
import {QrCode, qrCodeStore} from './entities/qrCode';
import {Machine, machineStore} from './entities/machine';
import {MachineType, machineTypeStore} from './entities/machineType';

export interface QrCodeManagementResponse {
  machines: Dictionary<Machine>;
  qrCodes: Dictionary<QrCode>;
  machineTypes: Dictionary<MachineType>;
}

export const loadQrCodeManagementData = () => async (dispatch: Dispatch) => {
  const response: QrCodeManagementResponse = await getQrCodeManagementState();
  await dispatch(batchActions([
    dispatch(qrCodeStore.actions.set(response.qrCodes)),
    dispatch(machineStore.actions.set(response.machines)),
    dispatch(machineTypeStore.actions.set(response.machineTypes))
  ], 'BATCH_SET_QRCODE_CONFIGURATION_STATE'));
};
