import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {CommonDispatch} from '../index';
import {
  archiveQrCode,
  downloadQrCode,
  generateQrCodes,
  printQrCode
} from '../../../api/qrCodeManagementApi';
import {createAction} from 'typesafe-actions';
import {GenerateQrCodesModalInterface} from '../../../pages/Configuration/QrCodeManagement/components/QrCodesModal/QrCodesModal';

export interface QrCode {
  id: number;
  code: string;
  printed: boolean;
  machineId: number | null;
  machineSerialNumber: string | null;
  archivedAt: string;
}

const selectors = createStandardSelectors(placeholder<QrCode>(), s => getEntities(s).qrCodes);
const actions = createStandardActions(placeholder<QrCode>(), 'QR_CODE/SET', 'QR_CODE/SAVE');
export const archiveQrCodeAction = createAction('QR_CODE/ARCHIVE')<number>();
export type QrCodeActions = GetActions<typeof actions> |  ReturnType<typeof archiveQrCodeAction>;

export const qrCodes = combineReducers({items: standardItemsReducer<QrCode, QrCodeActions>(actions)});
export const qrCodeStore = readonly({
  selectors: {
    ...selectors,
    getNonArchivedQrCodes: selector(s => selectors.getAsArray(s).filter(u => u.archivedAt === null)),
    getArchivedQrCodes: selector(s => selectors.getAsArray(s).filter(u => u.archivedAt !== null))
  },
  actions: {
    ...actions,
    generate: (value: GenerateQrCodesModalInterface) => async (dispatch: CommonDispatch) => {
      const response = await generateQrCodes(value);
      response.forEach((qrCode) => {
        dispatch(actions.save(qrCode));
      });
      return response;
    },
    archive: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await archiveQrCode(Number(id));
      dispatch(actions.save(response));
      return response;
    },
    print: (id: number) => async (dispatch: CommonDispatch) => {
      const response = await printQrCode(id);
      dispatch(actions.save(response));
      downloadQrCode(id);
      return response;
    }
  }
});
