import {Dispatch} from 'redux';
import {Dictionary} from '../util';
import {batchActions} from 'redux-batched-actions';
import {Label, labelStore} from './entities/label';
import {getVideoSelectionState} from '../../api/publicApi';
import {Video, videoStore} from './entities/video';
import {Machine, machineStore} from './entities/machine';

export interface VideoSelectionDataResponse {
  labels: Dictionary<Label>;
  videos: Dictionary<Video>;
  machines: Dictionary<Machine>;
}

export const loadVideoSelectionData = (id: string) => async (dispatch: Dispatch) => {
  const response: VideoSelectionDataResponse = await getVideoSelectionState(id);
  await dispatch(batchActions([
    dispatch(labelStore.actions.set(response.labels)),
    dispatch(videoStore.actions.set(response.videos)),
    dispatch(machineStore.actions.set(response.machines))
  ], 'BATCH_SET_QRCODE_CONFIGURATION_STATE'));
};
