import {User} from '../../redux/entities/user';
import {MachineCategory} from '../../redux/entities/machineCategory';
import {MachineType} from '../../redux/entities/machineType';
import {Machine} from '../../redux/entities/machine';
import {Label} from '../../redux/entities/label';
import {GenerateQrCodesModalInterface} from '../../../pages/Configuration/QrCodeManagement/components/QrCodesModal/QrCodesModal';

export function makeUser(): User {
  return {
    id: 0,
    email: '',
    archivedAt: null,
    roleId: '' as any as number
  };
}

export function makeMachineCategory(): MachineCategory {
  return {
    id: 0,
    name: ''
  };
}

export function makeMachineType(machineCategoryId: number): MachineType {
  return {
    id: 0,
    name: '',
    machineCategoryId: machineCategoryId
  };
}

export function makeMachine(machineTypeId: number): Machine {
  return {
    id: 0,
    productNumber: '',
    rentalId: '',
    serialNumber: '',
    machineTypeId: machineTypeId,
    description: ''
  };
}

export function makeLabel(machineCategoryId: number, parentId: string): Label {
  return {
    id: 0,
    name: '',
    parentId: parentId === '' ? '' as any as number : Number(parentId),
    machineCategoryId: machineCategoryId,
    videoId: ''
  };
}

export function makeQrCodeGenerationRequest(): GenerateQrCodesModalInterface {
  return {
    email: '',
    companyName: 'ISCO Industries',
    attention: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    amount: '' as any as number
  };
}
