import * as React from 'react';
import {Switch, Route, match} from 'react-router-dom';
import NotFound from '../../NotFound/NotFound';
import {
  convertComponentPaths,
  RoutePath,
  RoutePaths
} from '../../../router/RoutePaths';
import {RouteRenderer} from '../../../router/RouteRenderer';
import MachineCategories from './pages/MachineCategories/MachineCategories';
import MachineCategoryEditor from './pages/MachineCategoryEditor/MachineCategoryEditor';
import MachineTypes from './pages/MachineTypes/MachineTypes';
import MachineTypeEditor from './pages/MachineTypeEditor/MachineTypeEditor';
import Machines from './pages/Machines/Machines';
import MachineEditor from './pages/MachineEditor/MachineEditor';

interface Props {
  match: match;
}

const routes: RoutePath[] = [
  ...convertComponentPaths(
    {component: MachineCategories, paths: [RoutePaths.machineCategoryListPath]},
    {component: MachineCategoryEditor, paths: Object.values(RoutePaths.machineCategoryPaths)},
    {component: MachineTypes, paths: [RoutePaths.machineTypeListPath]},
    {component: MachineTypeEditor, paths: Object.values(RoutePaths.machineTypesPaths)},
    {component: Machines, paths: [RoutePaths.machineListPath]},
    {component: MachineEditor, paths: Object.values(RoutePaths.machinePaths)}
  )
];

const MachineConfigurationRoutes = (props: Props) => (
  <Switch>
    <RouteRenderer routePaths={routes}/>
    <Route component={NotFound}/>
  </Switch>
);

export default MachineConfigurationRoutes;
