import React, {useState} from 'react';
import {Container} from 'react-bootstrap';
import styles from './PublicRouter.module.scss';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import {useMount} from '../../hooks/useMount';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {bindActionCreators, Dispatch} from 'redux';
import {loadQrCodeManagementData} from '../../common/redux/qrCodeManagement';
import {WebState} from '../../redux/types/WebState';
import {RoutePaths} from '../../router/RoutePaths';
import {validateQrCode} from '../../api/qrCodeManagementApi';
import {Redirect} from 'react-router-dom';
import PublicRouterRoutes from './PublicRouterRoutes';
import GoogleAnalytics from '../../components/util/GoogleAnalytics';

type Props = RouteComponentProps<{id: string}> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function PublicRouter(props: Props) {
  const {match} = props;
  const params = new URLSearchParams(props.location.search);
  const [loading, setLoading] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState('');

  useMount(() => {
    async function isQrCodeValid(code: string) {
      try {
        await validateQrCode(code).then((result) => {
          if (result) {
            setRedirectUrl(`${RoutePaths.publicPaths.watch}?id=${code}`);
          } else {
            setRedirectUrl(`${RoutePaths.publicPaths.activate}?id=${code}`);
          }
        });
      } catch (e) {
        setRedirectUrl(RoutePaths.notFound);
      }
      setLoading(false);
    }
    isQrCodeValid(params.get('id')!);
  });

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Redirect to={redirectUrl} />;
    }
    return null;
  };

  const renderContent = () => {
    return (
      <>
        {renderRedirect()}
        <PublicRouterRoutes match={match}/>
        <GoogleAnalytics {...props}/>
      </>
    );
  };

  return (
      <Container fluid={true} className={styles['config-container']}>
        {loading ?  <CenteredSpinner/> : renderContent()}
      </Container>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) =>
  ({actions: bindActionCreators({loadVideos: loadQrCodeManagementData}, dispatch)});
const mapStateToProps = (state: WebState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(PublicRouter);
