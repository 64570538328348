import {
  number,
  object, string,
} from 'yup';
import {GenerateQrCodesModalInterface} from './QrCodesModal';

export const QrCodesModalSchema = object<GenerateQrCodesModalInterface>({
  email: string().required(),
  companyName: string().required(),
  attention: string().required(),
  address1: string().required(),
  address2: string(),
  city: string().required(),
  state: string().required(),
  zip: string().required(),
  amount: number().required('Amount is required').lessThan(100).positive('Must be a positive number.')
});
