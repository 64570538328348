import React from 'react';
import {WebState} from '../../../../../redux/types/WebState';
import {MachineCategory, machineCategoryStore} from '../../../../../common/redux/entities/machineCategory';
import {connect} from 'react-redux';
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next';
import {Link, RouteComponentProps} from 'react-router-dom';
import {RoutePaths} from '../../../../../router/RoutePaths';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';

type Props = RouteComponentProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function MachineCategories({machineCategories}: Props) {

  const actionColumnFormatter = (cell: any, machineCategory: MachineCategory) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <Link to={RoutePaths.machineTypeListPath.replace(':machineCategoryId', machineCategory.id.toString())}>
            <IconButton
              icon='pen'
              size={'1x'}
              color={'#005A9C'}
            />
          </Link>
        </div>
      </>
    );
  };

  const viewLabelsFormatter = (cell: any, machineCategory: MachineCategory) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <Link to={RoutePaths.machineCategoryPaths.View.replace(':machineCategoryId', machineCategory.id.toString())}>
            <IconButton
              icon='pen'
              size={'1x'}
              color={'#005A9C'}
            />
          </Link>
        </div>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      style: () => {
        return { width: '20rem'};
      }
    },
    {
      dataField: 'userActionsColumnOne',
      text: 'Manage Machine Types',
      isDummyField: true,
      formatter: actionColumnFormatter,
      headerStyle: () => {
        return {width: '20rem'};
      },
      style: () => {
        return { width: '20rem'};
      }
    },
    {
      dataField: 'userActionsColumnTwo',
      text: 'Manage Labels',
      isDummyField: true,
      formatter: viewLabelsFormatter,
      headerStyle: () => {
        return { width: '20rem'};
      },
      style: () => {
        return { width: '20rem'};
      }
    }
  ];
  return (
    <>
      <div style={{display: 'flex'}}>
        <h4 style={{display: 'flex', flexGrow: 1, alignItems: 'center', marginBottom: '10px'}}>Machine Categories</h4>
        <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
          <Link to={RoutePaths.machineCategoryPaths.New}>
            <IconButton
              icon={'plus-circle'}
              size={'2x'}
              iconToolTipText={'Add Machine Category'}
              color={'#005A9C'}
            />
          </Link>
        </div>
      </div>
      <BootstrapTable keyField='id' data={machineCategories} columns={columns}/>
    </>
  );
}

const mapStateToProps = (state: WebState) => ({machineCategories: machineCategoryStore.selectors.getAsArray(state)});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MachineCategories);
