import React from 'react';
import styles from './NotFound.module.scss';

const NotFound = () => (
  <div className={styles['not-found']}>
    <label>404</label>
  </div>
);

export default NotFound;
