import React from 'react';
import {WebState} from '../../../../../redux/types/WebState';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {machineTypeStore} from '../../../../../common/redux/entities/machineType';
import {RoutePaths, UrlParam} from '../../../../../router/RoutePaths';
import {useStandardEditor} from '../../../../../components/util/form-components/EditorForm/hooks';
import {makeMachine} from '../../../../../common/util/factory';
import {Link, RouteComponentProps} from 'react-router-dom';
import {EditorForm} from '../../../../../components/util/form-components/EditorForm/EditorForm';
import {InputColumn} from '../../../../../components/util/form-components/InputColumn';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {Machine, machineStore} from '../../../../../common/redux/entities/machine';
import {ExpandableTextBoxInput} from '../../../../../components/util/ExpandableTextBox/ExpandableTextBox';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import {machineCategoryStore} from '../../../../../common/redux/entities/machineCategory';

type Props = RouteComponentProps<{machineCategoryId: string; machineTypeId: string; machineId: string}>
  & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function MachineEditor(props: Props) {
  const {match: {params: {machineCategoryId, machineTypeId, machineId}},
    getMachineById, getMachineCategoryById, getMachineTypeById} = props;

  const applyUrl = (url: string, aCategoryId: UrlParam, bTypeId: UrlParam, cId: UrlParam) => url
    .replace(':machineCategoryId', String(aCategoryId))
    .replace(':machineTypeId', String(bTypeId))
    .replace(':machineId', String(cId));

  const getFieldName = (name: keyof Machine) => name;
  const getMachine = () => getMachineById(Number(machineId));
  const standardEditor = useStandardEditor<Machine>(
    'Machine Category',
    makeMachine(Number(machineTypeId)),
    props,
    s => ({
      getEditUrl: () =>  applyUrl(RoutePaths.machinePaths.Edit, machineCategoryId, machineTypeId, machineId),
      getCancelUrl: () => RoutePaths.machineListPath.replace(':machineCategoryId', machineCategoryId)
        .replace(':machineTypeId', machineTypeId),
      onLoadForm: async () => getMachine(),
      onNewForm: async () => makeMachine(Number(machineTypeId)),
      saveAndRedirect: async (form) => {
        await props.actions.upsertMachine(form);
        return RoutePaths.machineListPath.replace(':machineCategoryId', machineCategoryId)
          .replace(':machineTypeId', machineTypeId);
      },
      onDelete: async () => {
        await props.actions.deleteMachine(Number(machineId));
        return RoutePaths.machineListPath.replace(':machineCategoryId', machineCategoryId)
          .replace(':machineTypeId', machineTypeId);
      },
      footer: true
    }));

  const getPageHeaderArea = () => {
    const machineCategory = getMachineCategoryById(Number(machineCategoryId));
    const machineType = getMachineTypeById(Number(machineTypeId));
    const machine = getMachineById(Number(machineId));
    return (
      <>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Link
            to={RoutePaths.machineListPath.replace(':machineCategoryId', machineCategoryId).replace(':machineTypeId', machineTypeId)}
          >
            <IconButton
              icon={'angle-left'}
              size={'2x'}
              iconToolTipText={'Back'}
              color={'#005A9C'}
            />
          </Link>
          <div>
            <Link to={RoutePaths.machineCategoryListPath}>
              Machine Categories
            </Link>
            <label> {' > '} </label>
            {machineCategory.name}
            <label> {' > '} </label>
            <Link to={RoutePaths.machineTypeListPath.replace(':machineCategoryId', machineCategoryId)}>
              Machine Types
            </Link>
            <label> {' > '} </label>
            {machineType.name}
            <label> {' > '} </label>
            <Link to={RoutePaths.machineListPath.replace(':machineCategoryId', machineCategoryId).replace(':machineTypeId', machineTypeId)}>
              Machines
            </Link>
            <label> {' > '} </label>
            {machine !== undefined ? machine.serialNumber : 'New Machine'}
          </div>
          <h4> {machine !== undefined ? machine.serialNumber : 'New Machine'}</h4>
        </div>
      </>
    );
  };

  const {editable} = standardEditor;
  return (
    <>
      <div style={{marginBottom: '10px', display: 'flex', alignItems: 'flex-end'}}>
        {getPageHeaderArea()}
      </div>
      <EditorForm standardEditor={standardEditor}>
        {({values}) => (
          <>
            <InputColumn label='Product Number' columnSize={3} style={{paddingLeft: 0}}>
              <Input name={getFieldName('productNumber')} disabled={!editable}/>
            </InputColumn>
            <InputColumn label='Rental Id' columnSize={3} style={{paddingLeft: 0}}>
              <Input name={getFieldName('rentalId')} disabled={!editable}/>
            </InputColumn>
            <InputColumn label='Serial Number' columnSize={3} style={{paddingLeft: 0}}>
              <Input name={getFieldName('serialNumber')} disabled={!editable}/>
            </InputColumn>
            <InputColumn label='Description' columnSize={3} style={{paddingLeft: 0}}>
              <ExpandableTextBoxInput name={getFieldName('description')} editable={editable} rows={5}/>
            </InputColumn>
          </>
        )}
      </EditorForm>
    </>
  );
}

const mapStateToProps = (state: WebState) => ({
  getMachineById: machineStore.selectors.getById(state),
  getMachineTypeById: machineTypeStore.selectors.getById(state),
  getMachineCategoryById: machineCategoryStore.selectors.getById(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertMachine: machineStore.actions.upsert,
    deleteMachine: machineStore.actions.delete
  }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(MachineEditor);
