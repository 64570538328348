import React, {FormEvent, useState} from 'react';
import {connect} from 'react-redux';
import styles from './LabelModal.module.scss';
import {Form, Formik, FormikErrors} from 'formik';
import {Button, Form as BSForm, Modal} from 'react-bootstrap';
import {WebState} from '../../../../../../../redux/types/WebState';
import {InputRow} from '../../../../../../../components/util/form-components/InputRow';
import {Label} from '../../../../../../../common/redux/entities/label';
import {LabelModalSchema} from './LabelModalSchema';
import Input from '../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {DropdownInput} from '../../../../../../../components/util/form-components/formik-inputs/DropdownInput/DropdownInput';
import {convertToDropDownOptions} from '../../../../../../../common/util';
import {videoStore} from '../../../../../../../common/redux/entities/video';

type Props = {
  label: Label;
  onCancel: () => void;
  onSubmit: (label: Label) => void;
  editable: boolean;
  onTop?: boolean;
} & ReturnType<typeof mapStateToProps>;

function LabelModal(props: Props) {
  const {label, videoOptions, onTop, onSubmit, editable, onCancel} = props;
  const [lastSubmitted, setLastSubmitted] = useState(0);
  const getFieldName = (name: keyof Label) => name;

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: Label,
    validate: (values: Label) => Promise<FormikErrors<Label>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0 && Date.now() - lastSubmitted < 1000) {
      onSubmit(association);
    } else if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setLastSubmitted(Date.now());
    } else {
      onSubmit(association);
    }
  };

  return (
    <Modal
      backdropClassName={onTop ? styles['on-top'] : ''}
      show={true}
      size='lg'
      centered={true}
      onHide={() => null}
    >
      <Modal.Body>
        <Formik initialValues={label} validationSchema={LabelModalSchema} onSubmit={() => undefined}>
          {({values, errors, isValid, submitCount, validateForm, handleSubmit, setFieldValue}) => {
            return (
              <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <BSForm.Row>
                  <Modal.Title>Label</Modal.Title>
                </BSForm.Row>
                <InputRow label={'Name'} columnSize={8}>
                  <Input name={getFieldName('name')}/>
                </InputRow>
                <InputRow label={'Video (Optional)'} columnSize={5}>
                  <DropdownInput name={getFieldName('videoId')} dropdownData={videoOptions} isClearable={true}/>
                </InputRow>
                <BSForm.Row>
                  <BSForm.Group className={styles['form-buttons']}>
                    <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} className={styles['close-button']}>
                      {editable ? 'Cancel' : 'Close'}
                    </Button>
                    {editable && <Button data-tip='Double click submit to save form with errors' variant={'success'} type='submit'>Submit</Button>}
                  </BSForm.Group>
                </BSForm.Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state: WebState) => ({
  videoOptions: convertToDropDownOptions(videoStore.selectors.getAsArray(state))
});
export default connect(mapStateToProps)(LabelModal);
